import { useContext } from "react";
import { LeaderContext } from "components/util-components/LeaderProvider";

const useLeader = () => {
    const context = useContext(LeaderContext);
  
    if (!context) {
      throw new Error("useLeader must be within LeaderProvider");
    }
  
    return {
      leaderStatus: context.leaderStatus
    };
  };

export default useLeader;
