import { createContext, useEffect, useCallback, useContext } from "react";
import { matches } from "mqtt-pattern";
import { AppEmitter } from "app-events";
import useMqttState from "./useMqttState";
import Log from "log";

export const TopicSubscriptionContext = createContext();

export const TopicSubscriptionProvider = ({ topic, dispatchEventName, options = {}, children }) => {
 
    const { client, connectionStatus } = useMqttState();

    const callback = useCallback(
      (receivedTopic, receivedMessage) => {
        if ([topic].flat().some((rTopic) => matches(rTopic, receivedTopic))) {
          AppEmitter.emit(
            dispatchEventName,
            {
              topic: receivedTopic,
              message: receivedMessage,
            },
            true
          );
        }
      },
      [topic, dispatchEventName]
    );

    const subscribe = useCallback(async () => {
      if (client && client.connected) {
        client.subscribe(topic, options);
        client.on("message", callback);
        Log.debug("Subscribed to topic", topic);
      }
    }, [client, options, topic, callback]);
    const unsubscribe = useCallback(async () => {
      if (client) {
        client.off("message", callback);
        client.unsubscribe(topic);
        Log.debug("Un subscribed from topic", topic);
      }
    }, [client, topic, callback]);
  
    
  
    useEffect(() => {
      subscribe();
      return () => {
        unsubscribe();
      };
    }, [subscribe, unsubscribe]);
  
  
  return (
    <TopicSubscriptionContext.Provider
      value={{
        client,
        topic,
        connectionStatus
      }}
    >
      {children}
    </TopicSubscriptionContext.Provider>
  );
};



export const useTopicSubscription = () => {
    const context = useContext(TopicSubscriptionContext);

    if (!context) {
        throw new Error("useTopicSubscription must be within TopicSubscriptionContext");
    }

    return context;
}