import { v4 as uuidv4 } from "uuid";
import { AppEmitter, EventNames } from "app-events";

export const SenderTypes = {
  bot: "bot",
  user: "user",
  visitor: "visitor",
};
export const MessageFormats = {
  text: "text",
  form: "form",
  appointment: "appointment"
};
export const MessageTypes = {
  conversation: "conversation",
  aiConversation: "aiConversation",
  interaction: "interaction",
  engage: "engage",
  typing: "typing",
  stoppedTyping: "stoppedTyping",
  left: "left",
  meeting: "meeting"
};

class Message {
  constructor(sessionId, senderType, senderId, senderName, senderAvatar, time) {
    this.id = uuidv4();
    this.session = { id: sessionId };
    if (senderType === SenderTypes.bot) {
      this.sender = { type: senderType };
    } else {
      this.sender = { type: senderType, id: senderId, name: senderName, avatar: senderAvatar };
    }
    this.time = time;
  }

  toString() {
    return JSON.stringify(this);
  }

  async post(apiCall) {
    return await apiCall({...this});
  }
  publish(mqttClient, topic) {
    if(mqttClient) {
      mqttClient.publish(topic, this.toString());
    } else {
      AppEmitter.emit(EventNames.onSendTopicMessage(topic.split('/')[2]), {...this});
    }
  }
}

export class ChatMessage extends Message {
  constructor(sessionId, chatType, senderType, senderId, senderName, senderAvatar, time, value) {
    super(sessionId, senderType, senderId, senderName, senderAvatar, time);

    this.type = chatType;
    if (chatType === MessageTypes.conversation) {
      this.format = MessageFormats.text;
      this.value = value;
    }
    if(value) {
      this.value = value;
    }
  }
}



