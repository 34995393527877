import React from "react";
import { Menu, Dropdown } from "antd";
import {
  EditOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useUserSettings } from "hooks";
import UserAvatar from "components/shared-components/UserAvatar";
import { useAuth0 } from "@auth0/auth0-react";
import { useIntl } from "react-intl";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const NavProfile = () => {
  let history = useHistory();
  const intl = useIntl();
  const { userSettings } = useUserSettings();
  const { user, tenant } = userSettings;
  const { logout } = useAuth0();
  const userName = `${user.firstName} ${user.lastName}`;

  const onEditProfile = () => {
    history.push(`${APP_PREFIX_PATH}/settings/user?id=profile`);
  };
  const onSignOut = () => {
    logout({ returnTo: window.location.origin });
  };
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <UserAvatar src={user.avatar} fullName={userName} />
          <div className="pl-3">
            <h4 className="mb-0">{userName}</h4>
            <span className="text-muted">{tenant.name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key="1" onClick={onEditProfile}>
            <span>
              <EditOutlined />
              <span className="font-weight-normal">
                {intl.formatMessage({
                  id: "components.navprofile.editprofile",
                })}
              </span>
            </span>
          </Menu.Item>
          <Menu.Item key="2">
            <a href="https://qualifico.com" target="_blank" rel="noreferrer">
              <QuestionCircleOutlined />
              <span className="font-weight-normal">
                {intl.formatMessage({ id: "components.navprofile.help" })}
              </span>
            </a>
          </Menu.Item>
          <Menu.Item key="3" onClick={onSignOut}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">
                {intl.formatMessage({ id: "components.navprofile.signout" })}
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <UserAvatar src={user.avatar} fullName={userName} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
