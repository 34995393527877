import { createContext, useEffect, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { EventNames, useStatelessAppEvent, AppEmitter } from "app-events";
import Log from "log";
import { useApi } from "hooks";
import { SenderTypes } from "chat";
import { useLiveStream } from "hooks";
import Utils from "utils";
import { ApiObjects } from "service";
import Loading from "components/shared-components/Loading";
import { useTopicSubscriptionManager } from "mqtt-hooks";

export const LiveVisitorStreamContext = createContext();

export const LiveVisitorStreamProvider = ({ visitorId, children }) => {
  const { Api } = useApi();
  const { visitorTopic } = useTopicSubscriptionManager();
  const { channels } = useLiveStream();
  const [messages, setMessages] = useState([]);
  const [visitor, setVisitor] = useState(null);

  const notifyTabOnVisitorChange = useCallback((visitor) => {
    // notify tab on visitor update
    const {id, firstName, online} = visitor;
    AppEmitter.emit(EventNames.onVisitorUpdate, {id, firstName, online});
  },[]);

  const parseMessages = useCallback((visitorId, responseMessages) => {
    let messageHistory = [...responseMessages];
    let tempDate = null;

    for (let i = 0; i < messageHistory.length; i++) {
      const msg = messageHistory[i];
      const msgDate = new Date(msg.time);
      if (tempDate !== msgDate.getDay()) {
        tempDate = msgDate.getDay();
        // insert date message
        messageHistory.splice(i, 0, {
          id: uuidv4(),
          sender: { type: "visitor", id: visitorId },
          type: "conversation",
          format: "date",
          time: msg.time,
        });
        i = i + 1;
      }
    }
    // today
    if (tempDate !== new Date().getDay()) {
      messageHistory.splice(messageHistory.length, 0, {
        id: uuidv4(),
        sender: { type: "visitor", id: visitorId },
        type: "conversation",
        format: "date",
        time: new Date().toISOString(),
      });
    }
    return messageHistory;
  }, []);

  const getVisitor = useCallback(() => {
    Api.getObject(ApiObjects.Visitor, visitorId)
      .then((response) => {
        Log.debug("getVisitor", response);
        if (response.data && response.data !== "") {
          if (response.data.messages) {
            if (response.data.messages.length > 0) {
              setMessages((messages) => [
                ...messages,
                ...parseMessages(visitorId, response.data.messages),
              ]);
            }
          }
          setVisitor(response.data);
          // notify tab on visitor update
          notifyTabOnVisitorChange(response.data);

          // subscribe to visitor topic
          AppEmitter.emit(EventNames.onSubscribeTopic, {
            topic: visitorTopic(response.data.id),
            dispatchEventName: EventNames.onVisitorTopicMessage(response.data.id),
          });
        }
      })
      .catch((error) => {
        Log.error(error, "Error in Api.getVisitor");
      });
  }, [Api, visitorId, setVisitor, parseMessages, notifyTabOnVisitorChange, visitorTopic]);

  const handleVisitorRefresh = useCallback(
    (event) => {
      if (event.id === visitorId) {
        Api.getObject(ApiObjects.Visitor, event.id)
          .then((response) => {
            Log.debug("refresh getVisitor", response);
            if (response.data && response.data !== "") {
              delete response.data.messages;
              setVisitor(response.data);
              // notify tab on visitor update
              notifyTabOnVisitorChange(response.data);

               // subscribe to visitor topic
              AppEmitter.emit(EventNames.onSubscribeTopic, {
                topic: visitorTopic(response.data.id),
                dispatchEventName: EventNames.onVisitorTopicMessage(response.data.id),
          });
            }
          })
          .catch((error) => {
            Log.error(error, "Error in Api.getVisitor");
          });
      }
    },
    [Api, visitorId, setVisitor, notifyTabOnVisitorChange, visitorTopic]
  );

  const handleIncomingTopicMessage = useCallback(
    (event) => {
      try {
        if (!event.topic.includes(visitorId)) {
          return;
        }
        const msg = JSON.parse(new TextDecoder().decode(event.message));

        switch (msg.type) {
          case "engage":
            Log.debug("engage", msg);
            AppEmitter.emit(
              EventNames.onTyping(visitorId),
              { isTyping: false },
              true
            );
            AppEmitter.emit(
              EventNames.onChatStarted(visitorId),
              msg.sender,
              true
            );
            return;
          case "typing":
            if (msg.sender.type === SenderTypes.visitor) {
              Log.debug("typing", "");
              AppEmitter.emit(EventNames.onTyping(visitorId), {
                isTyping: true,
              });
            }
            return;
          case "stoppedTyping":
            if (msg.sender.type === SenderTypes.visitor) {
              Log.debug("stopped typing", "");
              AppEmitter.emit(EventNames.onTyping(visitorId), {
                isTyping: false,
              });
            }
            return;
          case "meeting":
          case "disconnect":
          case "left":
            return;
          default:
            break;
        }
        setMessages((messages) => [...messages, msg]);
        AppEmitter.emit(EventNames.onTyping(visitorId), { isTyping: false });
        AppEmitter.emit(EventNames.onNotifyVisitorMessage(visitorId), {
          increase: true,
        });
      } catch (error) {
        console.error(error);
      }
    },
    [visitorId]
  );

  const handleVisitorExit = useCallback(
    (event) => {
      if (event.id === visitor.id) {
        AppEmitter.emit(EventNames.onChatEnded(visitor.id), event);
        // notify tab on visitor update
        notifyTabOnVisitorChange({...visitor, online: false});

        setVisitor({
          ...visitor,
          online: false
        });
        setMessages((messages) => [
          ...messages,
          {
            id: uuidv4(),
            sender: { type: "visitor", id: event.id },
            type: "conversation",
            format: "label",
            time: new Date().toISOString(),
            value: `${visitor.firstName} Left`,
          },
        ]);
      }
    },
    [visitor, notifyTabOnVisitorChange]
  );
  const handleSocketDisconnected = useCallback(()=>{
    setVisitor(null);
    setMessages([]);
  },[]);

  const handleSocketConnected = useCallback(()=>{
    if (!visitor && channels.length > 0) {
      getVisitor();
    }
  },[visitor, channels, getVisitor]);

  useStatelessAppEvent(
    EventNames.onVisitorTopicMessage(visitorId),
    handleIncomingTopicMessage
  );
  useStatelessAppEvent(
    EventNames.onVisitorOffline(visitorId),
    handleVisitorExit
  );
  useStatelessAppEvent(
    EventNames.onVisitorRefresh(visitorId),
    handleVisitorRefresh
  );

  useStatelessAppEvent(EventNames.onSocketConnected, handleSocketConnected);
  useStatelessAppEvent(
    EventNames.onSocketDisconnected,
    handleSocketDisconnected
  );

  useEffect(() => {
    if (!visitor && channels.length > 0) {
      getVisitor();
    }
    return () => {
      // un subscribe from visitor topic
     if(visitor) AppEmitter.emit(EventNames.onUnSubscribeTopic, {topic: visitorTopic(visitor.id)});
    }
  }, [visitor, channels, getVisitor, visitorTopic]);

  if (Utils.isUndefined(visitor)) {
    return <Loading />;
  }
  return (
    <LiveVisitorStreamContext.Provider
      value={{
        visitor,
        messages,
      }}
    >
      {children}
    </LiveVisitorStreamContext.Provider>
  );
};
