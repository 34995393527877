import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import history from "./history";
import Api from "service";
import SimpleErrorMessage from "components/shared-components/SimpleErrorMessage";
import Utils from "utils";
import MsTeamsLogin from "components/util-components/MsTeamsLogin";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const getCustomParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  // handle user invitation
  if (
    searchParams &&
    searchParams.get("invitation") &&
    searchParams.get("organization")
  ) {
    return {
      invitation: searchParams.get("invitation"),
      organization: searchParams.get("organization"),
    };
  } 
  return null;
};

const isMsTeamsLogin = () => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams && searchParams.get("msteams_cid") && Utils.getTenantName() === "login") {
    return true;
  }
  return false;
}

if (isMsTeamsLogin()) {
  ReactDOM.render(
    <MsTeamsLogin/>,
    document.getElementById("root")
  );
} else {
  Api.getAuthSettings()
  .then((configJson) => {
    // Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
    // for a full list of the available properties on the provider
    const config = configJson.data;
    const customParams = getCustomParams();

    const providerConfig = {
      organization: config.organizationId,
      ...(customParams && customParams.organization && customParams.invitation &&
        customParams.organization === config.organizationId
        ? { invitation: customParams.invitation }
        : null),
      domain: config.domain,
      clientId: config.clientId,
      audience: config.audience,
      redirectUri: window.location.origin,
      onRedirectCallback,
    };

    ReactDOM.render(
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>,
      document.getElementById("root")
    );
  })
  .catch((error) => {
    console.error(error);
    ReactDOM.render(
      <SimpleErrorMessage message="Service unavailable. Please try again."/>,
      document.getElementById("root")
    );
  });

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
