import {useContext } from "react";
import { UserSettingsContext } from "components/util-components/UserContext";


const useUserSettings = () => {
    const context = useContext(UserSettingsContext);

    if (!context) {
        throw new Error("useUserSettings must be within UserSettingsContext");
    }

    return context;

}


export default useUserSettings;
