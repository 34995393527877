import { Avatar } from "antd";
import React from "react";
import Util from "utils";

const UserAvatar = ({ src, fullName, style }) => {
  return (
    <React.Fragment>
      {src ? (
        <Avatar
          src={src}
          {...(style && {
            style: style,
          })}
        />
      ) : (
        <Avatar
          {...(style && {
            style: style,
          })}
        >
          {Util.getInitials(fullName)}
        </Avatar>
      )}
    </React.Fragment>
  );
};

export default UserAvatar;
