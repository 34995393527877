import { createContext, useState, useEffect, useCallback } from "react";
import { useApi } from "hooks";
import Log from "log";
import Loading from "components/shared-components/Loading";
import { EventNames, useStatelessAppEvent } from "app-events";

export const UserSettingsContext = createContext();

export const UserSettingsProvider = ({ children }) => {
  const { Api } = useApi();
  const [userSettings, setUserSettings] = useState({isLoading: true});

  const getSettings = useCallback(() => {
    Api.getSettings().then((response) => {
      Log.debug("settings response", response);
      setUserSettings({
        loaded: true,
        ...response.data,
      });
    })
    .catch((error) => {
      Log.error(error);
      setUserSettings({loaded: true});
    });
  }, [Api]);

  useStatelessAppEvent(EventNames.onIntegrationCreated, getSettings);
  useStatelessAppEvent(EventNames.onWidgetSettingsChange, getSettings);

  useEffect(() => {
    if (!userSettings.loaded) {
      getSettings();
    }
  }, [getSettings, userSettings]);

  if (!userSettings) {
    return <Loading />;
  }
  return (
    <UserSettingsContext.Provider
      value={{
        userSettings,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};
