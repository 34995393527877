import { useContext } from "react";
import { LiveVisitorStreamContext } from "components/util-components/LiveVisitorStreamProvider";

const useLiveVisitorStream = () => {
    const context = useContext(LiveVisitorStreamContext);
  
    if (!context) {
      throw new Error("useLiveVisitorStream must be within LiveVisitorStreamContext");
    }
  
    return {
      visitor: context.visitor,
      messages: context.messages
    };
  };

export default useLiveVisitorStream;
