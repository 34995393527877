import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import Loading from "./components/shared-components/Loading";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { useAuth0 } from "@auth0/auth0-react";
import { LeaderProvider } from "components/util-components/LeaderProvider";
import { ApiProvider } from "components/util-components/ApiProvider";
import { UserSettingsProvider } from "components/util-components/UserContext";
import { getThemes } from "constants/Themes";
import { AppAnalytics } from "components/util-components/AppAnalytics";
import ErrorMessage from "components/shared-components/ErrorMessage";
const themes = getThemes(process.env.PUBLIC_URL);

const App = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  const getReturnTo = () => {
    if (
      !window.location.search ||
      window.location.search === "" ||
      window.location.search === "?"
    ) {
      return window.location.pathname;
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (
      searchParams &&
      searchParams.get("invitation") &&
      searchParams.get("organization")
    ) {
      return window.location.pathname;
    }
    return `${window.location.pathname}${window.location.search}`;
  };

  if (error) {
    return <ErrorMessage message={error.message}/>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: getReturnTo(),
      },
    });
  }

  return (
    <div className="App">
      {isAuthenticated && (
        <Provider store={store}>
          <AppAnalytics>
            <ApiProvider>
              <LeaderProvider>
                <UserSettingsProvider>
                  <ThemeSwitcherProvider
                    themeMap={{ main: themes[THEME_CONFIG.currentTheme] }}
                    defaultTheme="main"
                  >
                    <Router>
                      <Switch>
                        <Route path="/" component={Views} />
                      </Switch>
                    </Router>
                  </ThemeSwitcherProvider>
                </UserSettingsProvider>
              </LeaderProvider>
            </ApiProvider>
          </AppAnalytics>
        </Provider>
      )}
    </div>
  );
};

export default App;
