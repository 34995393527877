import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
//import { matches } from "mqtt-pattern";
import { AppEmitter, EventNames, useStatelessAppEvent } from "app-events";
import useMqttState from "./useMqttState";
import Log from "log";
import { useUserSettings } from "hooks";

export const TopicSubscriptionManagerContext = createContext();

export const TopicSubscriptionManagerProvider = ({
  options = {},
  children,
}) => {
  const { userSettings } = useUserSettings();
  const { client, connectionStatus } = useMqttState();
  const subscriptions = useRef([]);

  const callback = useCallback((receivedTopic, receivedMessage) => {
    //if ([topic].flat().some((rTopic) => matches(rTopic, receivedTopic))) {
    if (subscriptions.current[receivedTopic]) {
      AppEmitter.emit(
        subscriptions.current[receivedTopic],
        {
          topic: receivedTopic,
          message: receivedMessage,
        },
        true
      );
    }

    // }
  }, []);

  const subscribe = useCallback(
    async (topic, dispatchEventName) => {
      if (client && client.connected && !subscriptions.current[topic]) {
        if (Object.keys(subscriptions.current).length === 0) {
          client.on("message", callback);
          Log.debug("registered callback");
      }
        subscriptions.current[topic] = dispatchEventName;
        client.subscribe(topic, options);
        Log.debug("Subscribed to topic", topic);
      }
    },
    [client, options, callback]
  );

  const unsubscribe = useCallback(
    async (topic) => {
      if (client) {
        delete subscriptions.current[topic];
        client.unsubscribe(topic);
        if (Object.keys(subscriptions.current).length === 0) {
          client.off("message", callback);
          Log.debug("removed callback");
        }
        Log.debug("Un subscribed from topic", topic);
      }
    },
    [client, callback]
  );

  const unsubscribeAll = useCallback(() => {
    const topics = Object.keys(subscriptions.current);
    for (let i = 0; i < topics.length; i++) {
      unsubscribe(topics[i]);
    }
  }, [unsubscribe]);

  const handleSubscribe = useCallback(
    (event) => {
      subscribe(event.topic, event.dispatchEventName);
    },
    [subscribe]
  );
  const handleUnSubscribe = useCallback(
    (event) => {
      unsubscribe(event.topic);
    },
    [unsubscribe]
  );

  useStatelessAppEvent(EventNames.onSubscribeTopic, handleSubscribe);
  useStatelessAppEvent(EventNames.onUnSubscribeTopic, handleUnSubscribe);

  useEffect(() => {
    return () => {
      unsubscribeAll();
    };
  }, [unsubscribeAll]);

  return (
    <TopicSubscriptionManagerContext.Provider
      value={{
        client,
        connectionStatus,
        visitorTopic: (visitorId) =>
          `${userSettings.tenant.domain}/visitors/${visitorId}`,
      }}
    >
      {children}
    </TopicSubscriptionManagerContext.Provider>
  );
};

export const useTopicSubscriptionManager = () => {
  const context = useContext(TopicSubscriptionManagerContext);

  if (!context) {
    throw new Error(
      "useTopicSubscriptionManager must be within TopicSubscriptionManagerContext"
    );
  }

  return context;
};
