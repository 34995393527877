import axios from "axios";
import Util from "../utils";
//import visitor from 'assets/data/visitor.json';
export const ApiObjects = {
  User: "user",
  Channel: "channel",
  Interaction: "interaction",
  Integration: "integration",
  Routing: "routing",
  Visitor: "visitor",
  AiResource: "ai/resource",
  ReportEmail: "report/email"
};
class ApiService {
  constructor(token) {
    this.fetch = null;
    this.token = null;
    this.setToken(token);
  }

  setToken = (token) => {
    this.token = token;
    this.resetFetch();
  };

  resetFetch = () => {
    this.fetch = axios.create({
      baseURL: Util.getApiEndpoint(),
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  static getAuthSettings = () => {
    const aFetch = axios.create({
      baseURL: Util.getApiEndpoint(),
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return aFetch({
      url: "/auth/settings",
      method: "get",
    });
  };

  getObject = async (objectName, id) => {
    return await this.fetch({
      url: `/${objectName}/${id}`,
      method: "get",
    });
  };
  createObject = async (objectName, data) => {
    return await this.fetch({
      url: `/${objectName}`,
      method: "post",
      data,
    });
  };
  duplicateObject = async (objectName, id) => {
    return await this.fetch({
      url: `/${objectName}/duplicate`,
      method: "post",
      data: {id},
    });
  };
  updateObject = async (objectName, data) => {
    return await this.fetch({
      url: `/${objectName}/${data.id}`,
      method: "put",
      data,
    });
  };
  getObjectList = async (objectName, searchTerms) => {
    return await this.fetch({
      url: `/${objectName}${searchTerms && searchTerms.length > 0 ? "?" + searchTerms.join('&') : ""}`,
      method: "get",
    });
  };
  getObjectListNextPage = async (objectName, nextPageRef, searchTerms) => {
    return await this.fetch({
      url: `/${objectName}?next=${nextPageRef}${searchTerms && searchTerms.length > 0 ? "&" + searchTerms.join('&') : ""}`,
      method: "get",
    });
  };
  deleteObject = async (objectName, id) => {
    return await this.fetch({
      url: `/${objectName}/${id}`,
      method: "delete",
    });
  };

  patchObjectBatch = async (objectName, data) => {
    return await this.fetch({
      url: `/${objectName}/batch`,
      method: "patch",
      data,
    });
  };
  patchObject = async (objectName, id, data) => {
    return await this.fetch({
      url: `/${objectName}/${id}`,
      method: "patch",
      data,
    });
  };

  getUserIntegrations = async () => {
    return await this.fetch({
      url: "/user/integrations",
      method: "get",
    });
  };
  removeUserIntegration = async (appName) => {
    return await this.fetch({
      url: `/user/integrations/${appName}`,
      method: "delete",
    });
  };
  getSettings = async () => {
    return await this.fetch({
      url: "/user/settings",
      method: "get",
    });
  };
  getUsersLookup = async (count, includeTags, search) => {
    return await this.fetch({
      url: "/user/lookup",
      method: "get",
      params: { count, includeTags, search },
    });
  };

  getLiveChannelsVisitors = async () => {
    return await this.fetch({
      url: "/channel/live/visitor",
      method: "get",
    });
  };

  updateVisitor = async (data) => {
    return await this.fetch({
      url: `/visitor/${data.visitor.id}`,
      method: "patch",
      data: data,
    });
  };
  engage = async (id) => {
    return await this.fetch({
      url: `/visitor/${id}/engage`,
      method: "get",
    });
  };

  getWidgetSettings = async () => {
    return await this.fetch({
      url: `/widget/settings`,
      method: "get",
    });
  };

  getSupportedIntegrations = async () => {
    return await this.fetch({
      url: `/integration/supported?include=diff`,
      method: "get",
    });
  };
  getCrmMappings = async () => {
    return await this.fetch({
      url: `/integration/crm/mapping`,
      method: "get",
    });
  };
  updateWidgetSettings = async (settings) => {
    return await this.fetch({
      url: `/widget/settings`,
      method: "put",
      data: settings,
    });
  };
  uploadFile = async (file, directory, path) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("directory", directory);

    const upload = axios.create({
      baseURL: Util.getApiEndpoint(),
      timeout: 60000,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.token}`,
      },
    });
    return await upload.post(path ? path : `/file/upload`, formData);
  };

  testIntegrationConnection = async (data) => {
    return await this.fetch({
      url: `/integration/test`,
      method: "post",
      data
    });
  }

  registerMsTeamsUser = async (conversationId) => {
    return await this.fetch({
      url: `/user/settings/msteams`,
      method: "post",
      data: {conversationId}
    });
  };

  convertTeamsMeetingToken = async (data) => {
    return await this.fetch({
      url: `/integration/meeting/msteams/token`,
      method: "post",
      data
    });
  }

  getIntegrationCallback = async (app, code) => {
    return await this.fetch({
      url: `/integration/oauth-callback?app=${app}&code=${code}`,
      method: "get",
    });
  };

  createMeeting = async (visitorId) => {
    return await this.fetch({
      url: `/visitor/${visitorId}/meeting`,
      method: "post",
      data: {}
    });
  }

  getReport = async (filter) => {
    return await this.fetch({
      url: `/report?fromDate=${filter.fromDate}&toDate=${filter.toDate}&dateAggregate=${filter.dateAggregate}&entity=All&timezoneOffset=${(new Date()).getTimezoneOffset()}`,
      method: "get",
    });
  };

  reProcessAiResource = async (id) => {
    return await this.fetch({
      url: `/ai/resource/${id}/refresh`,
      method: "get",
    });
  };
}

export default ApiService;
