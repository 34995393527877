import React from 'react';
import { MqttContext } from "./MqttConnector";
const useMqttState = () => {
    const { connectionStatus, client } = React.useContext(MqttContext);
    
    return {
        connectionStatus,
        client
    };
  };
  export default useMqttState;