import React, { createContext, useEffect, useState, useMemo, useRef } from 'react';
import Log from "log";
import { connect } from 'mqtt';
import { setInterval, clearInterval } from 'worker-timers';
import { AppEmitter, EventNames } from "app-events";

export const MqttContext = createContext();
const MqttConnector = ({
  children,
  brokerUrl,
  options = { keepalive: 0 },
  enable = true
}) => {
  const keepAliveTimer = useRef();
  // Using a ref rather than relying on state because it is synchronous
  const clientValid = useRef(false);
  const [connectionStatus, setStatus] = useState('Offline');
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (!client && !clientValid.current && enable) {
      // This synchronously ensures we won't enter this block again
      // before the client is asynchronously set
      clientValid.current = true;
      setStatus('Connecting');
      //console.debug(`attempting to connect to ${brokerUrl}`);
      const mqtt = connect(brokerUrl, options);
      mqtt.on('connect', () => {
        Log.debug('on connect');

         // keep alive
         if(keepAliveTimer.current) clearInterval(keepAliveTimer.current);
         keepAliveTimer.current = setInterval(() => {
           mqtt._sendPacket({ cmd: 'pingreq' });
           //console.log("ping", new Date());
         }, 30 * 1000);

        setStatus('Connected');
        // For some reason setting the client as soon as we get it from connect breaks things
        setClient(mqtt);
        AppEmitter.emit(EventNames.onSocketConnected, {});
      });
      mqtt.on('reconnect', () => {
        Log.debug('on reconnect');
        setStatus('Reconnecting');
        AppEmitter.emit(EventNames.onSocketDisconnected, {});
      });
      mqtt.on('error', err => {
        if(keepAliveTimer.current) clearInterval(keepAliveTimer.current); // stop keep alive
        Log.error(`Connection error: ${err}`);
        setStatus(err.message);
        AppEmitter.emit(EventNames.onSocketDisconnected, {});
      });
      mqtt.on('offline', () => {
        if(keepAliveTimer.current) clearInterval(keepAliveTimer.current); // stop keep alive
        Log.debug('on offline');
        setStatus('Offline');
        AppEmitter.emit(EventNames.onSocketDisconnected, {});
      });
      mqtt.on('end', () => {
        if(keepAliveTimer.current) clearInterval(keepAliveTimer.current); // stop keep alive
        Log.debug('on end');
        setStatus('Offline');
        AppEmitter.emit(EventNames.onSocketDisconnected, {});
      });
    }
    else if (client && !enable && clientValid.current) {
      Log.debug('closing mqtt client');
      client.end(true);
      setClient(null);
      clientValid.current = false;
    }
  }, [client, clientValid, enable, brokerUrl, options, setStatus]);

  // Only do this when the component unmounts
  // useEffect(
  //   () => () => {
  //     if (client) {
  //       console.debug('closing mqtt client');
  //       client.end(true);
  //       setClient(null);
  //       clientValid.current = false;
  //     }
  //   },
  //   [client, clientValid],
  // );

  // This is to satisfy
  // https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-constructed-context-values.md
  const value = useMemo(
    () => ({
      connectionStatus,
      client
    }),
    [connectionStatus, client],
  );

  return <MqttContext.Provider value={value}>{children}</MqttContext.Provider>;
}

export default MqttConnector;

