import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { EventNames } from "app-events";
import { TopicSubscription, TopicSubscriptionManager } from "mqtt-hooks";
import { useUserSettings } from "hooks";
import { CrmMappingsProvider } from "components/util-components/CrmMappingsProvider";

export const AppViews = () => {
  const { userSettings } = useUserSettings();

  return (
    <TopicSubscription
      topic={`${userSettings.tenant.domain}/users/${userSettings.user.id}`}
      dispatchEventName={EventNames.onUserTopicMessage}
    >
      <TopicSubscriptionManager>
      <CrmMappingsProvider>
        <Suspense fallback={<Loading cover="content" />}>
          <Switch>
            <Route
              path={`${APP_PREFIX_PATH}/live`}
              component={lazy(() => import(`./live`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/settings`}
              component={lazy(() => import(`./settings`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/chat-history`}
              component={lazy(() => import(`./chat-history`))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/admin`}
              component={lazy(() => import("./admin"))}
            />
            <Route
              path={`${APP_PREFIX_PATH}/error`}
              component={lazy(() => import(`./error`))}
            />
            <Redirect
              from={`${APP_PREFIX_PATH}`}
              to={`${APP_PREFIX_PATH}/live`}
            />
          </Switch>
        </Suspense>
      </CrmMappingsProvider>
      </TopicSubscriptionManager>
    </TopicSubscription>
  );
};

export default React.memo(AppViews);
