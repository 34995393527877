import React from "react";
import { Card, Col, Row } from "antd";

const CloseWindow = (props) => {
 
  return (
    <div className="h-100">
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    width="50px"
                    src="/img/logo-sm.png"
                    alt=""
                  />
                </div>
                <Row justify="center" className="text-center p-1">
                  <Col xs={20} sm={20} md={20}>
                    <span>The Qualifico app is already opened in another window.</span>
                    <br/><br/>
                    <span><strong>{props.message}</strong></span>
                  </Col>
                </Row>
                <Row justify="center" className="text-center">
                  <Col xs={20} sm={20} md={20}>
                    {/* <Button onClick={close}>Close</Button>  */}
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CloseWindow;
