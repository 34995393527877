import { createContext, useCallback, useState, useReducer } from "react";
import { AppEmitter, EventNames, useStatelessAppEvent } from "app-events";
import Log from "log";
import { useApi } from "hooks";
import { useIntl } from "react-intl";
import VirtualChannels from "constants/VirtualChannels.json";
import { ApiObjects } from "service";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom";
//import testData from "assets/data/visitor-stream.json";

export const LiveStreamContext = createContext();

const stateReducer = (state, action) => {
  //Log.debug(action.type, action);
  if (!action.data || action.data.length === 0) {
    return [...state];
  }
  switch (action.type) {
    case "add": // add or replace the visitor obj in the state
      action.data.forEach((v) => {
        const i = state.findIndex((e) => e.id === v.id);
        if (i > -1) {
          // refresh
          if (
            v.channelId === VirtualChannels.ChatRequest &&
            state[i].channelId !== VirtualChannels.ChatRequest
          ) {
            v.sourceChannel = state[i].channelId; // save the original channel in case this is a chat request
          }
          // replace
          state.splice(i, 1, v);
          // notify on refresh
          AppEmitter.emit(
            EventNames.onVisitorRefresh(v.id),
            { id: v.id },
            true
          );
        } else {
          // add
          state.push(v);
        }
      });
      return [...state];
    case "update":
      action.data.forEach((v) => {
        const j = state.findIndex((e) => e.id === v.id);
        if (j > -1) {
          v.channelId = state[j].channelId;
          // replace
          state.splice(j, 1, v);
          // notify on refresh
          AppEmitter.emit(
            EventNames.onVisitorRefresh(v.id),
            { id: v.id },
            true
          );
        }
      });
      return [...state];
    case "remove": // offline
      action.data.forEach((v) => {
        const i = state.findIndex((e) => e.id === v.id);
        if (i > -1) {
          state[i].channelId = VirtualChannels.Offline;
        }
      });
      return [...state];
    case "clear":
      return [];
    default:
      return [...state, ...action.data];
  }
};

export const LiveStreamProvider = ({ children }) => {
  const history = useHistory();
  const intl = useIntl();
  const { Api } = useApi();

  const [state, dispatch] = useReducer(stateReducer, []);
  const [channels, setChannels] = useState([]);

  const loadChannels = useCallback(async () => {
    try {
      const response = await Api.getObjectList(ApiObjects.Channel);
      Log.debug("loadChannels", response);
      const offlineTitle = intl.formatMessage({
        id: "pages.live.channels.offline.title",
      });
      // set channels + the offline virtual channel
      setChannels([
        ...response.data,
        { id: VirtualChannels.Offline, name: offlineTitle, color: "black" },
      ]);
    } catch (error) {
      Log.error(error, "Error in Api.getChannels");
    }
  }, [Api, setChannels, intl]);

  const loadLiveVisitors = useCallback(async () => {
    try {
      const response = await Api.getLiveChannelsVisitors();
      Log.debug("loadLiveVisitors", response);
      const data = [];
      response.data.forEach((c) => {
        c.visitors.forEach((v) => {
          v.channelId = c.channelId;
          v.key = v.id;
          data.push(v);
        });
      });
      dispatch({ type: "add", data });
    } catch (error) {
      Log.error(error, "Error in Api.getLiveChannelsVisitors");
    }
  }, [Api]);

  const onLiveVisitor = useCallback((msg) => {
    msg.visitor.channelId = msg.channelId;
    msg.visitor.key = msg.visitor.id;

    if (msg.action === "remove") {
      // notify the live visitor page
      AppEmitter.emit(EventNames.onVisitorOffline(msg.visitor.id), {
        id: msg.visitor.id,
      });
    } else if (msg.channelId === VirtualChannels.ChatRequest) {
      AppEmitter.emit(EventNames.onChatRequest, { ...msg.visitor });
    }
    // update state
    dispatch({ type: msg.action, data: [msg.visitor] });
  }, []);

  const handleTopicMessage = useCallback(
    (event) => {
      try {
        const msg = JSON.parse(new TextDecoder().decode(event.message));
        Log.debug("User topic message", msg);
        switch (msg.type) {
          case "channel":
            onLiveVisitor(msg);
            break;

          default:
            break;
        }
      } catch (error) {
        Log.error(error);
        throw error;
      }
    },
    [onLiveVisitor]
  );
  const handleSocketConnected = useCallback(
    async (event) => {
      await loadChannels();
      await loadLiveVisitors();
    },
    [loadChannels, loadLiveVisitors]
  );

  const handleSocketDisconnected = useCallback(
    (event) => {
      dispatch({ type: "clear", data: [] });
      setChannels([]);
    },
    [setChannels]
  );
  const handleChannelsUpdate = useCallback(
    async (event) => {
      await loadChannels();
    },
    [loadChannels]
  );
  const onHandleOpenVisitor = useCallback(
    (visitor) => {
      if (visitor.external === true) {
        history.push(`${APP_PREFIX_PATH}/live/visitor/${visitor.id}`);
      }
    },
    [history]
  );
  useStatelessAppEvent(EventNames.onUserTopicMessage, handleTopicMessage);
  useStatelessAppEvent(EventNames.onChatRequestCounterCompleted, onLiveVisitor);
  useStatelessAppEvent(EventNames.onSocketConnected, handleSocketConnected);
  useStatelessAppEvent(
    EventNames.onSocketDisconnected,
    handleSocketDisconnected
  );
  useStatelessAppEvent(EventNames.onChannelsUpdate, handleChannelsUpdate);
  useStatelessAppEvent(EventNames.onNavigateToVisitor, onHandleOpenVisitor);

  return (
    <LiveStreamContext.Provider
      value={{
        visitors: state,
        channels,
      }}
    >
      {children}
    </LiveStreamContext.Provider>
  );
};
