import { trackAppException } from "components/util-components/AppAnalytics";


class Log {
    static debug = (message, param) => {
        if(process.env.REACT_APP_DEBUG === 'ON')
          console.debug(message, param);
      }
    static info = (message, param) => {
        console.log(message, param);
    }

    static error = (error, message) => {
        console.log(message);
        console.error(error);
        trackAppException(error, message);
    }
}

export default Log;