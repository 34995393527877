import { createContext, useCallback, useState, useEffect } from "react";
import { EventNames, useStatelessAppEvent } from "app-events";
import Log from "log";
import { useApi } from "hooks";
import Utils from "utils";

export const CrmMappingsContext = createContext();

export const CrmMappingsProvider = ({ children }) => {
  const { Api } = useApi();

  const [crmMappings, setMappings] = useState(null);

  const onLoad = useCallback(async () => {
    try {
      const response = await Api.getCrmMappings();
      Log.debug("crm mappings", response);
      if (response.data && response.data !== "" && response.data.app) {
        setMappings(response.data);
      }
    } catch (error) {
      Log.error(error, "Error in Api.getCrmMappings");
    }
  }, [Api, setMappings]);

  useStatelessAppEvent(EventNames.onIntegrationCreated, onLoad);

  useEffect(() => {
    if (Utils.isUndefined(crmMappings)) {
      onLoad();
    }
  }, [onLoad, crmMappings]);
  return (
    <CrmMappingsContext.Provider
      value={{
        crmMappings,
      }}
    >
      {children}
    </CrmMappingsContext.Provider>
  );
};
