import Log from "log";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { arrayMoveImmutable } from "array-move";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router-dom";
import useApi from "./useApi";
import utils from "utils";
import { useIntl } from "react-intl";
import { ApiObjects } from "service";

const useObjectList = (objectName, sort, defaultQuery, autoLoad=true) => {
  let history = useHistory();
  const intl = useIntl();
  const { Api } = useApi();
  const [isLoaded, setLoaded] = useState(false);
  const [list, setList] = useState([]);
  const [nextPageRef, setNextPageRef] = useState(null);
  const [searchTerm, setSearchTerm] = useState([defaultQuery]);

  const onLoad = useCallback(() => {
    setLoaded(false);
    // load data
    Api.getObjectList(objectName, searchTerm)
      .then((response) => {
        Log.debug(`get ${objectName}`, response);
        if (
          response.data &&
          Array.isArray(response.data) &&
          response.data.length > 0
        ) {
          if (sort) {
            let sorted = response.data.sort((a, b) => a.index - b.index);
            setList(sorted.map((r) => ({ ...r, key: r.id })));
          } else {
            setList(response.data.map((r) => ({ ...r, key: r.id })));
          }
        } else if (
          response.data &&
          !Array.isArray(response.data) &&
          response.data.items
        ) {
          setList(response.data.items.map((r) => ({ ...r, key: r.id })));
          // for paging
          if (response.data.hasNext) {
            setNextPageRef(response.data.next);
          }
        } else {
          setList([]);
        }
        
      })
      .catch((error) => {
        Log.error(error, `Error in Api.getObjectList ${objectName}`);
        history.push(`${APP_PREFIX_PATH}/error`);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [Api, history, searchTerm, objectName, sort]);

  const getNextPage = async () => {
    if (!nextPageRef) {
      return;
    }
    try {
      const response = await Api.getObjectListNextPage(objectName, nextPageRef, searchTerm);
      Log.debug(`get next ${objectName}`, response);
      if (
        response.data &&
        response.data.items &&
        response.data.items.length > 0
      ) {
        setList((list) => [
          ...list,
          ...response.data.items.map((r) => ({ ...r, key: r.id })),
        ]);
      }
      if (response.data && response.data.hasNext) {
        setNextPageRef(response.data.next);
      } else {
        setNextPageRef(null);
      }
    } catch (error) {
      Log.error(error);
    }
  };
  const deleteListObject = (
    rowId,
    successMessageId,
    errorMessageId,
    postDeleteCallback
  ) => {
    Modal.confirm({
      title: intl.formatMessage({
        id: "pages.list.messages.delete.title",
      }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({
        id: "pages.list.messages.delete.content",
      }),
      okText: intl.formatMessage({
        id: "pages.list.messages.delete.yes",
      }),
      okType: "danger",
      cancelText: intl.formatMessage({
        id: "pages.list.messages.delete.no",
      }),
      onOk() {
        Api.deleteObject(objectName, rowId)
          .then((response) => {
            Log.debug(`deleted ${objectName}`, response);
            const objKey = "id";
            let data = list;
            data = utils.deleteArrayRow(data, objKey, rowId);
            setList(data);
            message.success(
              intl.formatMessage({
                id: successMessageId,
              })
            );
            if (postDeleteCallback) {
              postDeleteCallback();
            }
          })
          .catch((error) => {
            Log.error(error);
            message.error(
              intl.formatMessage({
                id: errorMessageId,
              })
            );
          });
      },
    });
  };
  const duplicateObject = (
    id
  ) => {
    Modal.confirm({
      title: intl.formatMessage({
        id: "pages.list.messages.duplicate.title",
      }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({
        id: "pages.list.messages.duplicate.content",
      }),
      okText: intl.formatMessage({
        id: "pages.list.messages.duplicate.yes",
      }),
      okType: "danger",
      cancelText: intl.formatMessage({
        id: "pages.list.messages.duplicate.no",
      }),
      onOk() {
        Api.duplicateObject(objectName, id)
          .then((response) => {
            Log.debug(`duplicate ${objectName}`, response);

            if (response.data && response.data.id) {
              message.success(
                intl.formatMessage({
                  id: "pages.list.messages.duplicate.message.success",
                })
              );
              let recordPath = "";
              if (objectName === ApiObjects.Interaction) {
                recordPath = "admin/interactions";
              } else if (objectName === ApiObjects.Channel) {
                recordPath = "admin/channels";
              } else if (objectName === ApiObjects.Routing) {
                recordPath = "admin/routingrules";
              }
              history.push(`${APP_PREFIX_PATH}/${recordPath}/${response.data.id}`);

            } else {
              message.error(
                intl.formatMessage({
                  id: "pages.list.messages.duplicate.message.error",
                })
              );
            }
          })
          .catch((error) => {
            Log.error(error);
            message.error(
              intl.formatMessage({
                id: "pages.list.messages.duplicate.message.error",
              })
            );
          });
      },
    });
  };
  const onStatusChange = (checked, record) => {
    Api.patchObject(objectName, record.id, { active: checked })
      .then((response) => {
        const index = list.findIndex((r) => r.id === record.id);
        record.active = checked;
        list.splice(index, 1, record);
        setList([...list]);

        message.success(
          intl.formatMessage({
            id: "pages.list.messages.active.success",
          })
        );
      })
      .catch((error) => {
        Log.error(error);
        message.error(
          intl.formatMessage({
            id: "pages.list.messages.active.fail",
          })
        );
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        list.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const patchValues = [];
      // re index
      for (let i = 0; i < newData.length; i++) {
        newData[i].index = i;
        patchValues.push({ id: newData[i].id, index: i });
      }
      if (patchValues.length === 0) return;

      Api.patchObjectBatch(objectName, patchValues)
        .then((response) => {
          setList([...newData]);
        })
        .catch((error) => {
          Log.error(error);
        });
    }
  };

  const onSearch = (searchTermExp, searchValue) => {
    Log.debug("on search:", searchValue);

    if(searchValue === "") {
      setSearchTerm([defaultQuery]);
    } else {
      setSearchTerm(defaultQuery? [searchTermExp, defaultQuery] : [searchTermExp]);
    }
  };
  useEffect(() => {
    if(autoLoad) onLoad();
  }, [onLoad, autoLoad]);

  return {
    list,
    isLoaded,
    reLoad: onLoad,
    setList,
    deleteListObject,
    duplicateObject,
    onStatusChange,
    onSortEnd,
    getNextPage,
    onSearch,
  };
};

export default useObjectList;
