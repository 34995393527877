import {useContext } from "react";
import { ApiContext } from "components/util-components/ApiProvider";


const useApi = () => {
    const context = useContext(ApiContext);

    if (!context) {
        throw new Error("useApi must be within ApiContext");
    }

    return context;
}


export default useApi;
