import React from "react";
import { Button, Card, Col, Row, message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

const ErrorMessage = props => {
  const {logout} = useAuth0();
  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  }
  return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={20} sm={20} md={20} lg={7}>
						<Card>
							<div className="my-4">
								<div className="text-center">
									<img className="img-fluid" width="50px" src="/img/logo-sm.png" alt="" />
								</div>
								<Row justify="center" className="text-center p-1">
									<Col xs={20} sm={20} md={20} >
                                        <span>{props.message}</span>
									</Col>
								</Row>
                                <Row justify="center" className="text-center">
									<Col xs={20} sm={20} md={20}>
                                        <Button onClick={onLogout}>Logout</Button>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default ErrorMessage

export const quotaErrorHandler = (error, entity) => {
	if(error.response.status === 429) {
		message.error(`${entity} quota exceeded. Please contact support.`);
		return true;
	}
	return false;
}
