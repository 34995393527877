import { createContext, useState, useMemo, useEffect } from "react";
import ApiService from "service";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/shared-components/Loading";

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  const api = useMemo(() => new ApiService(token), [token]); 

  useEffect(() => {
    const getToken = async () => {
      const t = await getAccessTokenSilently();
      setToken(t);
      api.setToken(t);
    }
    if (!token) {
      getToken();
    }
  }, [api, token, getAccessTokenSilently]);

  if(!token) {
    return (<Loading/>);
  }

  return (
    <ApiContext.Provider
      value={{
        Api: api
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
