import { useEffect, useState, useCallback, useRef } from "react";
import EventEmitter from "eventemitter3";
import Log from "log";
import { useChannelEvent, channel, elector, ChannelEmitter } from "./broadcastChannel";

const inAppEmitter = new EventEmitter();

export const EventNames = {
  // connection
  onSocketConnected: "onSocketConnected",
  onSocketDisconnected: "onSocketDisconnected",
  onChannelsUpdate: "onChannelsUpdate",

  // navigation
  onNavigateToVisitor: "onNavigateToVisitor",
  // integrations
  onIntegrationCreated: "onIntegrationCreated",
  // user events
  onUserTopicMessage: "onUserTopicMessage", // message receieved from mqtt

  // chat request
  onChatRequestCounterCompleted: "onChatRequestCounterCompleted",
  onChatRequest: "onChatRequest",

  // widget
  onWidgetSettingsChange: "onWidgetSettingsChange",

  // cross tab sync
  onExternalMessage: "message", // messages coming from outside the widget

  // window events
  onLeaderSelected: "onLeaderSelected",
  onWindowVisibilityChange: "visibilitychange",
  onWindowFocus: "focus",

  // visitor related
  onChatStarted: (visitorId) => `onChatStarted-${visitorId}`,
  onChatEnded: (visitorId) => `onChatEnded-${visitorId}`,
  onVisitorTopicMessage: (visitorId) => `onVisitorTopicMessage-${visitorId}`, // message receieved from mqtt
  onNotifyVisitorMessage: (visitorId) => `onNotifyVisitorMessage-${visitorId}`,
  onSendTopicMessage: (visitorId) => `onSendTopicMessage-${visitorId}`, // send mqtt message from leader
  onTyping: (visitorId) => `onTyping-${visitorId}`,
  onVisitorOffline: (visitorId) => `onVisitorOffline-${visitorId}`,
  onVisitorRefresh: (visitorId) => `onVisitorRefresh-${visitorId}`,
  onVisitorUpdate: "onVisitorUpdate",
  onSubscribeTopic: `onSubscribeTopic`,
  onUnSubscribeTopic: `onUnSubscribeTopic`,

  // online meeting
  onStartOnlineMeeting: (visitorId) => `onStartOnlineMeeting-${visitorId}`,
  onOnlineMeetingEnded: "onOnlineMeetingEnded",
  onPlayerLoaded: "onPlayerLoaded",
  onPlayerTeamsUserRequest: "onPlayerTeamsUserRequest",
  onPlayerMeetingOrganizerJoined: "onPlayerMeetingOrganizerJoined",
};

export const AppEmitter = {
  on: (event, fn) => inAppEmitter.on(event, fn),
  once: (event, fn) => inAppEmitter.once(event, fn),
  off: (event, fn) => inAppEmitter.off(event, fn),
  emit: (event, payload, broadcast) => {
    inAppEmitter.emit(event, payload);

    if (broadcast) {
      ChannelEmitter.emit(event, payload);
      Log.debug(`Dispatched channel event ` + event, payload);
    }
  },
};

Object.freeze(AppEmitter);

const useEventState = () => {
  const [subscribers, setSubscribers] = useState([]);
  const publish = useCallback(
    (eventValue) => {
      let executed = [];
      for (let i = 0; i < subscribers.length; i++) {
        const subscriber = subscribers[i];
        if (eventValue && eventValue.nodeId) {
          // publish for specific nodes
          if (subscriber.context.node.id === eventValue.nodeId) {
            subscriber.callback(subscriber.context.node);

            executed.push(subscriber.context.node.id);
          }
        } else {
          // publish all
          subscriber.callback(subscriber.context.node);

          executed.push(subscriber.context.node.id);
        }
      }
      //Log.debug("executed:", executed);
      setSubscribers((subscribers) =>
        subscribers.filter((s) => !executed.includes(s.context.node.id))
      );
    },
    [subscribers]
  );
  const subscribe = useCallback((context, callback) => {
    //console.log('subscribe', subscribers)
    setSubscribers((subscribers) => [...subscribers, { context, callback }]);
  }, []);

  return {
    EventState: {
      on: subscribe,
      emit: publish,
    },
  };
};
export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useDocumentEvent = (event, callback) => {
  useEffect(() => {
    document.addEventListener(event, callback);
    return () => document.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useStatefullAppEvent = (event, isOnce) => {
  const { EventState } = useEventState();

  const onEmitted = useCallback(
    (event) => {
      EventState.emit(event);
    },
    [EventState]
  );

  const addSubscriber = useCallback(
    (context, callback) => {
      EventState.on(context, callback);
    },
    [EventState]
  );

  useEffect(() => {
    if (isOnce) {
      AppEmitter.once(event, onEmitted);
    } else {
      AppEmitter.on(event, onEmitted);
    }
    return () => AppEmitter.off(event, onEmitted);
  }, [event, isOnce, onEmitted]);

  return { add: addSubscriber };
};

export const useStatelessAppEvent = (event, callback, isOnce) => {
  useEffect(() => {
    if (isOnce) {
      AppEmitter.once(event, callback);
    } else {
      AppEmitter.on(event, callback);
    }
    return () => AppEmitter.off(event, callback);
  }, [event, isOnce, callback]);
};

export const useBroadcastChannelListener = () => {
  const locker = useRef(false);
  const selected = useRef(false);

  const onChannelPacket = useCallback((packet) => {
    AppEmitter.emit(packet.type, packet.value);
    Log.debug(`Received channel event:`, packet);
  }, []);

  const elect = useCallback(() => {
    locker.current = true;
    // elector listeners
    elector.awaitLeadership().then(() => {
      selected.current = true;
      AppEmitter.emit(EventNames.onLeaderSelected, { selected: true });
    });
    elector.onduplicate = () => {
      console.error("duplicate leaders");
    };

    // wait for election. If not elected, fire none leader
    setTimeout(() => {
      if (!selected.current) {
        AppEmitter.emit(EventNames.onLeaderSelected, { selected: false });
      }
    }, 3500);
  }, []);

  useChannelEvent(onChannelPacket);

  useEffect(() => {
    if (!locker.current) {
      elect();
    }
  }, [elect]);

  return { elector, channel };
};
