import React from "react";
import { Card, Col, Row } from "antd";
import { getThemes } from "constants/Themes";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "configs/AppConfig";
const themes = getThemes(process.env.PUBLIC_URL);

const SimpleErrorMessage = props => {

  return (
	<ThemeSwitcherProvider
    themeMap={{ main: themes[THEME_CONFIG.currentTheme] }}
    defaultTheme="main"
  >
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={20} sm={20} md={20} lg={7}>
						<Card>
							<div className="my-4">
								<div className="text-center">
									<img className="img-fluid" width="50px" src="/img/logo-sm.png" alt="" />
								</div>
								<Row justify="center" className="text-center p-1">
									<Col xs={20} sm={20} md={20} >
                                        <span>{props.message}</span>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
		</ThemeSwitcherProvider>
	)
}

export default SimpleErrorMessage
