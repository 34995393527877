import { createContext, useCallback, useState } from "react";
import {
  AppEmitter,
  EventNames,
  useBroadcastChannelListener,
  useStatelessAppEvent,
  useWindowEvent,
} from "app-events";
import Log from "log";

export const LeaderContext = createContext();

export const LeaderProvider = ({ children }) => {
  const [leaderStatus, setLeader] = useState("pending");

  const handleLeaderSelected = useCallback((event) => {
    Log.debug("Leader Selected:", event.selected);
    if (event.selected === true) {
      setLeader("selected");
    } else {
      // check if the url is live visitor
      if (window.location.pathname.includes("/pages/live/visitor/")) {
        AppEmitter.emit(
          EventNames.onNavigateToVisitor,
          {
            id: window.location.pathname.replace("/pages/live/visitor/", ""),
            firstName: "...",
            external: true
          },
          true
        );
        setLeader("rejectedRedirect");
      } else {
        setLeader("rejected");
      }
    }
  }, []);

  const handleExternalMessage = useCallback((event) => {
    if (event.data.type) {
      switch (event.data.type) {
        case "onPlayerLoaded":
          AppEmitter.emit(EventNames.onPlayerLoaded, event.data.request);
          break;
        case "onPlayerTeamsUserRequest":
          AppEmitter.emit(
            EventNames.onPlayerTeamsUserRequest,
            event.data.request
          );
          break;
        case "onPlayerMeetingEnded":
          AppEmitter.emit(EventNames.onOnlineMeetingEnded, {});
          break;
        case "onPlayerMeetingOrganizerJoined":
          AppEmitter.emit(EventNames.onPlayerMeetingOrganizerJoined, {});
          break;
        default:
          break;
      }
    }
  }, []);

  // connect to broadcast channel
  useBroadcastChannelListener();
  // listen for channel leader
  useStatelessAppEvent(EventNames.onLeaderSelected, handleLeaderSelected);

  useWindowEvent(EventNames.onExternalMessage, handleExternalMessage);

  return (
    <LeaderContext.Provider
      value={{
        leaderStatus,
      }}
    >
      {children}
    </LeaderContext.Provider>
  );
};
