import React from "react";
import FlagsList from "assets/data/flags.json";
import { useUserSettings } from "hooks";

const buildMap = (userSettings) => {
  if (userSettings && userSettings.features) {
    if (userSettings.features.ai === true) { // ai
      //FlagsList.navigation.airesource.visible = true;
      FlagsList.pages["ai-resources"].visible = true;
      FlagsList.components.workflow.categories.ai.visible = true;
    }
  }
  return FlagsList;
}

const getFlag = (featuresMap, featureName) => {
  return getProp(featuresMap, featureName.split("."));
};
const getProp = (object, path) => {
  if (path.length === 1) return object[path[0]];
  else if (path.length === 0) return null;
  else {
    if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
    else {
      object[path[0]] = {};
      return getProp(object[path[0]], path.slice(1));
    }
  }
};

export const getFeatureFlag = (userSettings, featureName) => {
  const featuresMap = buildMap(userSettings);
  const flag = getFlag(featuresMap, featureName);
  if (flag) {
    return flag;
  }

  return {
    visible: true,
  };
};

const FeatureFlag = ({ children, featureName }) => {
  const {userSettings} = useUserSettings();
  const featuresMap = buildMap(userSettings);
  const flag = getFlag(featuresMap, featureName);

  if (flag) {
    if (!flag.visible) {
      if (flag.message) {
        return <React.Fragment>{flag.message}</React.Fragment>;
      }
      return <React.Fragment></React.Fragment>;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default FeatureFlag;
