import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH } from "configs/AppConfig";

import { LiveStreamProvider } from "components/util-components/LiveStreamProvider";
import { useUserSettings, useLeader, useBodyClass } from "hooks";
import { MqttConnector } from "../mqtt-hooks";
import Utils from "../utils";
import ErrorMessage from "components/shared-components/ErrorMessage";
import Loading from "components/shared-components/Loading";
import CloseWindow from "components/shared-components/CloseWindow";

export const Views = (props) => {
  // context
  const { leaderStatus } = useLeader();
  const { userSettings } = useUserSettings();
  const { tenant } = userSettings;
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  if ((!tenant || !tenant.socket) && userSettings.isLoading === true) {
    return <Loading/>;
  } else if((!tenant || !tenant.socket) && !userSettings.isLoading) {
    return <ErrorMessage message="App failed to load. Please retry in few minutes."/>;
  } else if (leaderStatus === "pending") {
    return <Loading/>;
  } else if (leaderStatus === "rejected") {
    return <CloseWindow/>;
  } else if (leaderStatus === "rejectedRedirect") {
    return <CloseWindow message="The visitor record was opened in the main Live page"/>;
  }
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <LiveStreamProvider>
          <MqttConnector
            enable={true}
            brokerUrl={tenant.socket.mqttBroker}
            options={Utils.getMqttOptions(tenant.domain, userSettings.user.id.toString())}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to={APP_PREFIX_PATH} />
              </Route>
              <Route path={APP_PREFIX_PATH}>
                <AppLayout direction={direction} location={location} />
              </Route>
            </Switch>
          </MqttConnector>
        </LiveStreamProvider>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
