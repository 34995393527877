import { useContext } from "react";
import { CrmMappingsContext } from "components/util-components/CrmMappingsProvider";

const useCrmMappings = () => {
    const context = useContext(CrmMappingsContext);
  
    if (!context) {
      throw new Error("useCrmMappings must be within CrmMappingsContext");
    }
  
    return {
        crmMappings: context.crmMappings
    };
  };

export default useCrmMappings;
