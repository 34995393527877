import { useEffect, useReducer } from "react";
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import Util from "utils";

export const channel = new BroadcastChannel(Util.getHostName());
export const elector = createLeaderElection(channel);

export const ChannelEmitter = {
    on: (fn) => channel.addEventListener('message', fn),
    off: (fn) => channel.removeEventListener('message', fn),
    emit: (type, value) => channel.postMessage({
        type,
        value
      }),
    exit: () => channel.close()
  };

  Object.freeze(ChannelEmitter);


  export const useChannelEvent = (callback) => {
    useEffect(() => {
      ChannelEmitter.on(callback);
      return () => ChannelEmitter.off(callback);
    }, [callback]);
  };
  function reducer(state, action) {
    return {...state, ...action};
  }
  export const useChannelState = (packetType) => {
   
    const [state, dispatch] = useReducer(reducer, {});
  
    const onPacket = (packet) => {
      if (packet.type === packetType ) {
        dispatch({...packet.value });
      }
    }
    const setState = (data) => {
      dispatch({...data });
      ChannelEmitter.emit(packetType, data);
    }
    useChannelEvent(onPacket);
  
    return [state, setState];
  }
  
  
  
  