import { useContext } from "react";
import { LiveStreamContext } from "components/util-components/LiveStreamProvider";

const useLiveStream = () => {
    const context = useContext(LiveStreamContext);
  
    if (!context) {
      throw new Error("useLiveStream must be within LiveStreamContext");
    }
  
    return {
      visitors: context.visitors,
      channels: context.channels
    };
  };

export default useLiveStream;
