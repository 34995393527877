import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });
let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @return {void}
     */
    const initialize = () => {

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
              connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
              extensions: [reactPlugin],
              extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
              }
            }
        });

        appInsights.loadAppInsights();
    };

    return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
