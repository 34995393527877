import React, { useState } from "react";
import { Button, Card, Col, Input, Row } from "antd";
import { THEME_CONFIG } from "configs/AppConfig";
import { getThemes } from "constants/Themes";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
const themes = getThemes(process.env.PUBLIC_URL);

const MsTeamsLogin = props => {
    const [tenant, setTenant] = useState("");
const onClick = (e) => {
    if (tenant && tenant !== "") {
      window.location.replace(
        `https://${tenant.trim()}.app.qualifico.com/pages/settings/msteams${window.location.search}`
      );
    }
  }
  return (
    <ThemeSwitcherProvider
    themeMap={{ main: themes[THEME_CONFIG.currentTheme] }}
    defaultTheme="main"
  >
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={20} sm={20} md={15} lg={15}>
						<Card>
							<div className="my-4">
								<div className="text-center">
									<img className="img-fluid" width="50px" src="/logo.png" alt="" />
								</div>
								<Row justify="center" className="text-center p-1">
									<Col xs={20} sm={20} md={20} lg={15}>
                                        <h2>Please enter your Organization Name</h2>
									</Col>
								</Row>
                                <Row justify="center" className="text-center p-1">
									<Col xs={20} sm={20} md={20} lg={15}>
                                        <Input onChange={(e) => setTenant(e.target.value)} placeholder="Organization Name" id="tenantName" suffix=".app.qualifico.com"/>
									</Col>
								</Row>
                                <Row justify="center" className="text-center p-1">
									<Col xs={20} sm={20} md={20} lg={15}>
                                        <Button type="primary" onClick={onClick} >Register App</Button>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
        </ThemeSwitcherProvider>
	)
}

export default MsTeamsLogin
