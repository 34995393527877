export const getThemes = (prefix) => {
  return {
    dark: `${prefix}/css/dark-theme.css`,
    light: `${prefix}/css/light-theme.css`,

    red: `${prefix}/css/red-theme.css`,
    cyan: `${prefix}/css/cyan-theme.css`,
    orange: `${prefix}/css/orange-theme.css`,
    volcano: `${prefix}/css/volcano-theme.css`,
    lime: `${prefix}/css/lime-theme.css`,
    gold: `${prefix}/css/gold-theme.css`,
    magenta: `${prefix}/css/magenta-theme.css`,
    geekblue: `${prefix}/css/geekblue-theme.css`,
    purple: `${prefix}/css/purple-theme.css`,
    yellow: `${prefix}/css/yellow-theme.css`,
    green: `${prefix}/css/green-theme.css`,

    "red-dark": `${prefix}/css/red-dark-theme.css`,
    "cyan-dark": `${prefix}/css/cyan-dark-theme.css`,
    "orange-dark": `${prefix}/css/orange-dark-theme.css`,
    "volcano-dark": `${prefix}/css/volcano-dark-theme.css`,
    "lime-dark": `${prefix}/css/lime-dark-theme.css`,
    "gold-dark": `${prefix}/css/gold-dark-theme.css`,
    "magenta-dark": `${prefix}/css/magenta-dark-theme.css`,
    "geekblue-dark": `${prefix}/css/geekblue-dark-theme.css`,
    "purple-dark": `${prefix}/css/purple-dark-theme.css`,
    "yellow-dark": `${prefix}/css/yellow-dark-theme.css`,
    "green-dark": `${prefix}/css/green-dark-theme.css`,
  };
};
