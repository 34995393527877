import {
  SettingTwoTone,
  HomeFilled,
  ApartmentOutlined,
  FilterOutlined,
  AppstoreAddOutlined,
  ToolOutlined,
  UserOutlined,
  NodeExpandOutlined,
  MessageFilled,
  SettingFilled,
  LineChartOutlined,
  FileAddOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "live",
    path: `${APP_PREFIX_PATH}/live`,
    title: "sidenav.live",
    icon: HomeFilled,
    breadcrumb: false,
    roles: ["user", "admin"],
    submenu: [],
  },
  {
    key: "history",
    path: `${APP_PREFIX_PATH}/chat-history`,
    title: "sidenav.chatHistory",
    icon: MessageFilled,
    breadcrumb: false,
    roles: ["user", "admin"],
    submenu: [],
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings/user`,
    title: 'sidenav.userSettings',
    icon: SettingFilled,
    breadcrumb: false,
    roles: ["user", "admin"],
    submenu: []
  },
  {
    key: "admin",
    path: `${APP_PREFIX_PATH}/admin`,
    title: "sidenav.admin",
    icon: SettingTwoTone,
    breadcrumb: false,
    roles: ["admin"],
    submenu: [
      {
        key: "dashboard",
        path: `${APP_PREFIX_PATH}/admin/dashboard`,
        title: "sidenav.admin.dashboard",
        icon: LineChartOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/admin/users`,
        title: "sidenav.admin.users",
        icon: UserOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "integrations",
        path: `${APP_PREFIX_PATH}/admin/integrations`,
        title: "sidenav.admin.integrations",
        icon: AppstoreAddOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "channels",
        path: `${APP_PREFIX_PATH}/admin/channels`,
        title: "sidenav.admin.channels",
        icon: FilterOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "interactions",
        path: `${APP_PREFIX_PATH}/admin/interactions`,
        title: "sidenav.admin.interactions",
        icon: ApartmentOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "routingrules",
        path: `${APP_PREFIX_PATH}/admin/routingrules`,
        title: "sidenav.admin.routing",
        icon: NodeExpandOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "widget",
        path: `${APP_PREFIX_PATH}/admin/widget`,
        title: "sidenav.admin.widget",
        icon: ToolOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
      {
        key: "airesource",
        path: `${APP_PREFIX_PATH}/admin/ai-resources`,
        title: "sidenav.admin.aiResources",
        icon: FileAddOutlined,
        breadcrumb: false,
        roles: ["admin"],
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
